.base{
    background-image: url('../assets/cloudy-background.png');
    background-size: cover;
    background-position: center;
}

.icons{
    position: relative;
    width: 60%;
}

.icons:before{
    content:'';
    position: absolute;
    bottom: 0;
    left: -50px;
    width: 35px;
    height: 25px;
    background-image: url('../assets/space-planet.png');
}
.icons:after{
    content:'';
    position: absolute;
    bottom: -20px;
    right: -70px;
    width: 57px;
    height: 52px;
    background-image: url('../assets/astronaute.png');
}