.active {
    z-index: 10;
    overflow: visible;
    position: relative;
}

.active:before {
    left: -11px;
    top: -10px;
    overflow: visible;
    z-index: -1;
    position: absolute;
    content: '';
    display: block;
    width: 112px;
    height: 160px;
    background-image: url('../assets/selected-step.svg');
    background-size: cover;
    background-repeat: no-repeat;

}

.passed svg {
    filter: grayscale(1);
}